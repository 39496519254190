<template>
  <div>
    <el-row :gutter="12">
      <el-col :span="12" :xs="24" :md="12" :sm="12">
        <div class="main_content" style="overflow:hidden ">
          <div id="shareContent">
            <div class="main">
              <span class="main__title">基本信息(内)</span>
            </div>
            <el-form class="newForm main_content" label-suffix="：">
              <el-form-item label="项目名称">
                {{ infoData.name || '暂无' }}
              </el-form-item>
              <el-form-item label="意向达人" v-if="infoData.type===1">
                <div v-if="infoData?.intentions?.length > 0">
                  <span :key="i.id" v-for="i in infoData.intentions">{{ i?.nickname || '' }}</span></div>
                <div v-else>暂无意向达人</div>
              </el-form-item>
              <el-form-item label="品牌">
                {{ infoData.brand || '暂无' }}
                <span style="color: #E6A23C">（是否寄回：{{ infoData.is_send_back == 1 ? '是' : '否' }}）</span>
              </el-form-item>
              <el-form-item label="所属集团">
                {{ infoData?.group?.display_name || '暂无' }}
              </el-form-item>
              <el-form-item label="品牌背书">
                {{ infoData?.brand_intro || '暂无' }}
              </el-form-item>
              <el-form-item label="合作平台">
                <span :key="i.value" v-for="i in infoPlatforms"> {{ i.name }} </span>
<!--                <span style="color: #E6A23C;padding-left: 20px">{{-->
<!--                    infoData.coop ? infoData.coop.display_name : ''-->
<!--                  }}</span>-->
              </el-form-item>
              <el-form-item label="合作形式" v-if="infoData.type===1">
                {{ infoData?.coop?.display_name }}
              </el-form-item>
              <el-form-item label="是否平台下单">
                {{ infoData.is_platform_pay === 1 ? '是' : '否' || '暂无' }}
              </el-form-item>
              <el-form-item label="有无特别激励政策">
                {{ Number(infoData?.is_policy) === 1 ? '有' : '无' || '' }} /
                {{ infoData.policy || '暂无政策' }}
              </el-form-item>
              <el-form-item label="授权平台">
                <span v-for="(item,index) in infoData?.auth_platform_alias" :key="item+index">   {{ item }},   </span>
              </el-form-item>
              <el-form-item label="期望档期">
                {{ infoData.expect_start }}至{{ infoData.except_end }}
              </el-form-item>
              <el-form-item label="直播明细">
                {{ infoData.live_detail }}
              </el-form-item>
              <el-form-item label="其他要求">
                {{ infoData.other_desc }}
              </el-form-item>
              <el-form-item label="报名时间">
                {{ infoData.apply_start?.slice(0, 10) }} 至 {{ infoData.apply_end?.slice(0, 10) }}
              </el-form-item>
              <el-form-item label="创建人">
                {{ infoData?.create_user?.nickname || '暂无' }}
              </el-form-item>
              <el-form-item style="color: red" label="说明">报名时不备注默认都接受</el-form-item>
            </el-form>
            <div class="main">
              <span class="main__title">产品信息</span>
            </div>
            <div class="main_content" :key="item.id" v-for="item in infoData.product_list">
              <el-form class="newForm " label-suffix="：">
                <el-row>
                  <el-col :span="19">
                    <div class="top_box"><p class="top_box__title">{{ item.product }}</p></div>
                    <el-form-item label="是否保量">
                      {{
                        item.is_quantity === 1 ? `是` : '否' || '暂无'
                      }}
                      <span style="color: #E6A23C;margin-left: 10px" v-if="item.is_quantity === 1">{{
                          `费比：${item.qty_policy},直播佣金：${item.live_cmn}`
                        }}</span>
                    </el-form-item>
                    <el-form-item label="挂车佣金" v-if="infoData.type===1">
                      {{ item.is_cart === 1 ? '是' : '否' }}
                      <span  style="color: #E6A23C;margin-left: 10px" v-if="item.is_cart === 1">{{
                          ` 挂车佣金：${item.cart_rate},挂车机制：${item.cart_mechanism}`
                        }}</span>
                    </el-form-item>
                    <el-form-item label="是否全网统一机制">
                      {{ item.unify === 1 ? '是' : '否' }}
                      <span v-if="item.unify===2" style="color: #E6A23C;margin-left: 10px">
                        特殊机制说明： {{ item.special_remark }}
                      </span>
                    </el-form-item>
                    <el-form-item label="是否有签独家">
                      {{ item.exclusive === 1 ? '是' : '否' }}
                      <span style="color: #E6A23C;margin-left: 10px" v-if="item.exclusive === 1">{{
                          ` 独家机制：${item.other_activity}`
                        }}</span>
                    </el-form-item>
                    <el-form-item label="是否可签保价一个月">
                      {{ item.support_value === 1 ? '是' : '否' }}
                    </el-form-item>
                    <el-form-item label="产品附件">
                     <span v-for="item in item.inquiry_project_attachment" :key="item.id">
                <i class="el-icon-download" style="color: #ff3176"></i>
                 <a :href="item.full_path" target="_blank">{{ item.original || '附件下载' }}</a>
              </span>
                    </el-form-item>
                    <el-form-item label="产品卖点">
                      {{ item.intro }}
                    </el-form-item>
                    <el-form-item label="直播机制">
                      {{ item.live_mec }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <img
                        style="width: 100px; height: 100px;float: right;right: 10px"
                        :src="item.cover_url"
                        fit="contain"/>
                  </el-col>
                </el-row>

              </el-form>
            </div>
          </div>

          <div v-clipboard:copy="downFrom.shareContentData"
               v-clipboard:error="onError"
               v-clipboard:success="onCopyInfo"
               style="height: 80px;transform:scale(0.6);float: right;right: -40px" >
            <BirdButton :text="'复制文本'"></BirdButton>
          </div>
          <div style="height: 80px;transform:scale(0.6);float: right;right: -40px" @click="clickShare('shareContent')">
            <BirdButton :text="'预览'"></BirdButton>
          </div>
        </div>
      </el-col>
      <!--    -->
      <el-col :span="12" :xs="24" :md="12" :sm="12">
        <div id="share2Content">
          <div class="main_content" style="overflow:hidden;">
            <div class="main">
              <span class="main__title">基本信息(外)</span>
            </div>
            <el-form class="newForm main_content" label-suffix="：" ref="form">
              <el-form-item label="贵司名称">
                {{ infoData.brand || '' }}
              </el-form-item>
              <el-form-item label="品牌">
                {{ infoData.brand || '暂无' }}
                <span style="color: #E6A23C">（是否寄回：{{ infoData.is_send_back == 1 ? '是' : '否' }}）</span>
              </el-form-item>
              <el-form-item label="所属集团">
                {{ infoData?.group?.display_name || '暂无' }}
              </el-form-item>
              <el-form-item label="品牌背书">
                {{ infoData?.brand_intro || '暂无' }}
              </el-form-item>
              <el-form-item label="合作平台">
                <span :key="i.value" v-for="i in infoPlatforms"> {{ i.name }} </span>
<!--                <span style="color: #E6A23C;padding-left: 20px">{{-->
<!--                    infoData.coop ? infoData.coop.display_name : ''-->
<!--                  }}</span>-->
              </el-form-item>
              <el-form-item label="合作形式" v-if="infoData.type===1">
                {{ infoData?.coop?.display_name }}
              </el-form-item>
              <el-form-item label="产品名称">
                <span v-for="item in infoData.product_list" :key="item.id"> {{ item.product }}</span>
              </el-form-item>
              <el-form-item
                  label="产品资料"
                  v-show="intro!==''">
                <span v-html="intro"></span>
              </el-form-item>
              <el-form-item
                  label="期望档期"
                  v-show="infoData.expect_start!==''">
                {{ infoData.expect_start }} 至 {{ infoData.except_end }}
              </el-form-item>
              <el-form-item label="直播明细">
                {{ infoData.live_detail }}
              </el-form-item>
              <el-form-item
                  label="其他要求">
                {{ infoData.other_desc }}
              </el-form-item>
              <el-form-item style="color: red" label="说明">报名时不备注默认都接受</el-form-item>
            </el-form>
          </div>
        </div>
        <div v-clipboard:copy="downFrom.share2ContentData"
             v-clipboard:error="onError"
             v-clipboard:success="onCopyInfo"
             style="height: 80px;transform:scale(0.6);float: right;right: -40px" >
          <BirdButton :text="'复制文本'"></BirdButton>
        </div>
        <div style="height: 80px;transform:scale(0.6);float: right;right: -40px" @click="clickShare('share2Content')">
          <BirdButton
              :text="'预览'"></BirdButton>
        </div>

      </el-col>
    </el-row>
    <el-dialog :title="`分享信息预览`" :visible.sync="dialogImageVisible" @close="closefn" center width="50%">
      <div>
        <el-row>
          <ul>
            <li>
              <a @click="downs" href="#" style="float: right;margin-bottom: 10px"><span class="iconfont icon-qiandao"> 点击下载【{{
                  btnTitle
                }}】到本地 </span></a>
            </li>
          </ul>
        </el-row>

        <el-image
            :src="imgUrl"
            alt="档期"
            fit="fill"
            style="width: auto; height: auto"></el-image>

        <el-row>
          <el-col :span="24">
            <el-button @click="downs" size="large" style="width: 100%;background-color:#67C23A;margin-top: 20px "
                       type="success">点击下载【{{
                btnTitle
              }}】到本地
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BirdButton from '@/components/Style/birdButton.vue'
import html2canvas from 'html2canvas'

export default {
  name: 'infoCard',
  data() {
    return {
      infoData: {},
      //已有平台
      infoPlatforms: [],
      projectId: '',
      copyInfo: '请重试',
      copyData: '请重试',
      intro: '',
      other_desc: '',
      live_detail: '',
      dialogTitle: '',
      dialogImageVisible: false,
      clickfn: false,
      btnTitle: '基本信息',
      imgUrl: null, //图片链接
      downFrom: {
        inner:[
          {field: 'name', label:'项目名称'},
          {field: 'intentions', label:'意向达人'},
          {field: 'brand', label:'品牌'},
          {field: 'group', label:'所属集团'},
          {field: 'brand_intro', label:'品牌背书'},
          {field: 'infoPlatforms', label:'合作平台'},
          {field: 'coop', label:'合作形式'},
          {field: 'is_platform_pay', label:'是否平台下单'},
          {field: 'is_policy', label:'有无特别激励政策'},
          {field: 'auth_platform_alias', label:'授权平台'},
          {field: 'schedule_date', label:'期望档期'},
          {field: 'live_detail', label:'直播明细'},
          {field: 'other_desc', label:'其他要求'},
          {field: 'enroll_date', label:'报名时间'},
          {field: 'create_user', label:'创建人'},
        ],
        product:[
          {field: 'product', label:'产品名称'},
          {field: 'is_quantity', label:'是否保量'},
          {field: 'is_cart', label:'挂车佣金'},
          {field: 'unify', label:'是否全网统一机制'},
          {field: 'exclusive', label:'是否有签独家'},
          {field: 'other_activity', label:'独家机制'},
          {field: 'support_value', label:'是否可签保价一个月'},
          {field: 'intro', label:'产品卖点'},
          {field: 'live_mec', label:'直播机制'},
        ],
        outside:[
          {field: 'company', label:'贵司名称'},
          {field: 'brand', label:'品牌'},
          {field: 'group', label:'所属集团'},
          {field: 'brand_intro', label:'品牌背书'},
          {field: 'infoPlatforms', label:'合作平台'},
          {field: 'coop', label:'合作形式'},
          {field: 'product_list', label:'产品名称'},
          {field: 'intro', label:'产品资料'},
          {field: 'schedule_date', label:'期望档期'},
          {field: 'live_detail', label:'直播明细'},
          {field: 'other_desc', label:'其他要求'},
        ],
        list: [],
        shareContentData: '',
        share2ContentData: '',
      }
    }
  },
  components: {
    BirdButton
  },
  methods: {
    onCopyInfo() {
      this.$message({
        message: '复制成功！',
        type: 'success'
      })
    },
    onError() {
      this.$message({
        message: '复制失败！',
        type: 'error'
      })
    },
    async getInfo() {
      let { info } = await this.$api.infoInquiryProject({ id: this.projectId })
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      let platforms = list
      platforms.forEach((i) => {
        if (i.name === 'package') {
          i.children.forEach((v) => {
            platforms.push(v)
          })
        }
      })
      // let regexp = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|\\&|-)+)/g
      // this.intro = info['intro'].replace(regexp, function($url) {
      //   return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      // })
      // this.other_desc = info['other_desc'].replace(regexp, function($url) {
      //   return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      // })
      // this.live_detail = info['live_detail'].replace(regexp, function($url) {
      //   return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      // })
      //InquiryKolList
      this.$nextTick(() => {
        // this.infoData = info
        let mapNewArray = []
        for (let i in platforms) {
          info.platforms.forEach((item) => {
            if (item !== 'package' && item === platforms[i].name) {
              mapNewArray.push({ name: platforms[i].display_name, value: item })
            }
          })
        }
        this.infoPlatforms = mapNewArray
        this.infoData = info
        this.$emit('infoPlatform', this.infoPlatforms)
        this.$emit('update:infoStatus', { status: info.status, is_apply: info.is_apply, note: info.other_desc })
        // 复制基本信息(内)
        this.copyDataRow('shareContent')
        // 复制基本信息(外)
        this.copyDataRow('share2ContentData')
      })

    },
    clickShare(id) {
      const shareContent = document.getElementById(id) // 需要绘制的部分的 (原生）dom 对象 ，注意容器的宽度不要使用百分比，使用固定宽度，避免缩放问题
      html2canvas(shareContent).then(canvas => {
        this.imgUrl = canvas.toDataURL('image/png')
        if (this.imgUrl !== '') {
          this.dialogImageVisible = true
          this.loading = false
        }
      })
    },
    downs() {
      var alink = document.createElement('a')
      alink.href = this.imgUrl
      alink.download = this.btnTitle //图片名
      alink.click()
    },
    closefn() {
      this.clickfn = true//显示
      this.dialogImageVisible = false
    },
    copyDataRow(id) {
      this.downFrom.list = []
      if (id === 'shareContent') {
        this.copyInner()
        this.copyProduct()
        this.downFrom.shareContentData = this.downFrom.list.join(`\n`)
      } else {
        this.copyOutside()
        this.downFrom.share2ContentData = this.downFrom.list.join(`\n`)
      }
      // console.log(this.downFrom.list.join(`\n`))
    },
    copyInner() {
      const _this = this
      _this.downFrom.list.push('基本信息(内)')
      this.downFrom.inner.map(function(item) {
        switch (item.field) {
          case 'intentions':
            if (_this.infoData.type === 1) {
              if (_this.infoData[item.field].length > 0) {
                let cArr = []
                _this.infoData[item.field].map(function(i) {
                  cArr.push(i.nickname)
                })
                _this.downFrom.list.push(item.label + '：' + cArr.join('、'))
              } else {
                _this.downFrom.list.push(item.label + '：暂无意向达人')
              }
            }
            break
          case 'brand':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData[item.field] ?? '暂无') + '(是否寄回：' + (Number(_this.infoData.is_send_back) === 1 ? '是' : '否') + ')')
            break
          case 'group':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData[item.field]?.display_name ?? '暂无'))
            break
          case 'infoPlatforms':
            if (_this.infoPlatforms && _this.infoPlatforms.length > 0){
              let cArr = []
              _this.infoPlatforms.map(function(i) {
                cArr.push(i.name)
              })
              _this.downFrom.list.push(item.label + '：' + cArr.join('、'))
            } else {
              _this.downFrom.list.push(item.label + '：')
            }
            break
          case 'brand_intro':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData && _this.infoData[item.field] ? _this.infoData[item.field] : '暂无'))
            break
          case 'coop':
            if (_this.infoData.type === 1) {
              _this.downFrom.list.push(item.label + '：' + (_this.infoData?.coop?.display_name));
            }
            break
          case 'is_platform_pay':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData[item.field] === 1 ? '是' : '否' || '暂无'))
            break
          case 'is_policy':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData[item.field] === 1 ? '有' : '无' || '') + '/' + (_this.infoData.policy || '暂无政策'))
            break
          case 'auth_platform_alias':
            if (_this.infoData && _this.infoData[item.field] && _this.infoData[item.field].length > 0){
              let cArr = []
              _this.infoData[item.field].map(function(i) {
                cArr.push(i)
              })
              _this.downFrom.list.push(item.label + '：' + cArr.join('、'))
            } else {
              _this.downFrom.list.push(item.label + '：')
            }
            break
          case 'schedule_date':
            _this.downFrom.list.push(item.label + '：' + _this.infoData?.expect_start + ' 至 ' + _this.infoData?.except_end)
            break
          case 'enroll_date':
            _this.downFrom.list.push(item.label + '：' + _this.infoData?.apply_start.slice(0, 10) + ' 至 ' + _this.infoData?.apply_end.slice(0, 10))
            break
          case 'create_user':
            _this.downFrom.list.push(item.label + '：' + _this.infoData?.create_user?.nickname || '暂无')
            break
          default:
            _this.downFrom.list.push(item.label + '：' + (_this.infoData && _this.infoData[item.field] ? _this.infoData[item.field] : ''))
            break
        }
      })
    },
    copyProduct() {
      const _this = this
      _this.downFrom.list.push('产品信息')
      if (_this.infoData && _this.infoData.product_list && _this.infoData.product_list.length > 0) {
        _this.infoData.product_list.map(function(p_info) {
          _this.downFrom.product.map(function(item) {
            switch (item.field) {
              case 'is_quantity':
                _this.downFrom.list.push(item.label + '：' + (Number(p_info.is_quantity) === 1 ? '是' : '否' || '暂无')
                  + (Number(p_info.is_quantity) === 1 ? ('(费比：' + p_info.qty_policy + ', 直播佣金：' + p_info.live_cmn + ')') : ''))
                break
              case 'is_cart':
                if (Number(_this.infoData.type) === 1) {
                  if (Number(p_info.is_cart) === 1) {
                    _this.downFrom.list.push(item.label + '：是' + ('(挂车佣金：' + p_info?.cart_rate + ', 挂车机制：' + (p_info?.cart_mechanism || '') + ')'))
                  } else {
                    _this.downFrom.list.push(item.label + '：否')
                  }
                }
                break
              case 'unify':
                _this.downFrom.list.push(item.label + '：' + (p_info.unify === 1 ? '是' : '否')
                  + (p_info.unify === 2 ? ('(特殊机制说明：' + p_info?.special_remark + ')') : ''))
                break;
              case 'exclusive':
                _this.downFrom.list.push(item.label + '：' + (p_info.exclusive === 1 ? '是' : '否')
                  + (p_info.exclusive === 1 ? ('(独家机制：' + p_info.other_activity + ')') : ''))
                break
              case 'support_value':
                _this.downFrom.list.push(item.label + '：' + (p_info.support_value === 1 ? '是' : '否'))
                break
              default:
                _this.downFrom.list.push(item.label + '：' + (p_info && p_info[item.field] ? p_info[item.field] : ''))
                break
            }
          })
        })
      }
    },
    copyOutside() {
      const _this = this
      _this.downFrom.list.push('基本信息(外)')
      this.downFrom.outside.map(function(item) {
        switch (item.field) {
          case 'company':
            _this.downFrom.list.push(item.label + '：' + _this.infoData.brand);
            break
          case 'brand':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData.brand || '暂无')
              + '(是否寄回：' + (_this.infoData.is_send_back === 1 ? '是' : '否') + ')');
            break
          case 'group':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData[item.field]?.display_name ?? '暂无'))
            break
          case 'brand_intro':
            _this.downFrom.list.push(item.label + '：' + (_this.infoData?.brand_intro ?? '暂无'))
            break
          case 'infoPlatforms':
            if (_this.infoPlatforms && _this.infoPlatforms.length > 0){
              let cArr = []
              _this.infoPlatforms.map(function(i) {
                cArr.push(i.name)
              })
              _this.downFrom.list.push(item.label + '：' + cArr.join('、'))
            } else {
              _this.downFrom.list.push(item.label + '：')
            }
            break
          case 'coop':
            if (_this.infoData.type === 1) {
              _this.downFrom.list.push(item.label + '：' + (_this.infoData?.coop?.display_name));
            }
            break
          case 'product_list':
            if (_this.infoData && _this.infoData[item.field] && _this.infoData[item.field].length > 0){
              let cArr = []
              _this.infoData[item.field].map(function(i) {
                cArr.push(i.product)
              })
              _this.downFrom.list.push(item.label + '：' + cArr.join('、'))
            } else {
              _this.downFrom.list.push(item.label + '：')
            }
            break
          case 'intro':
            break
          case 'schedule_date':
            _this.downFrom.list.push(item.label + '：' + _this.infoData?.expect_start + ' 至 ' + _this.infoData?.except_end)
            break
          default:
            _this.downFrom.list.push(item.label + '：' + (_this.infoData && _this.infoData[item.field] ? _this.infoData[item.field] : ''))
            break
        }
      })
    },
  },
  mounted() {
    this.projectId = this.$route.params.id
    this.getInfo()
  }
}
</script>
<style lang="scss" scoped>
.main_content {
  border: 1px solid #DCDFE6;
  padding: 10px;
  width: 94%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.main {
  background: #ffffff;
  border-radius: 20px;
  color: rgba(0, 0, 0, .85);
  padding: 10px 16px;
  margin-bottom: 10px;
  margin-top: 0;

  &__title {
    color: black;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    padding-left: 12px;
    //margin-bottom: 18px;
  }

  &__title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #92CC68;
    border-radius: 4px;
  }
}

.top_box {
  background: #ffffff;
  border-radius: 20px;

  &__title {
    color: black;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
  }

  &__title::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 10px;
    height: 10px;
    border: 2px solid #3590CC;
    background-color: white;
    border-radius: 50%;
  }
}
</style>

